import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import { useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import { PlusCircleDotted } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import KeysHwListOrg from '../../components/KeysHwListOrg/KeysHwListOrg'
import Layout from '../../components/Layout'
import { LicenseKeyModal } from '../../components/LicenseKeyModal'
import PageError from '../../components/PageError'
import Paginator from '../../components/Paginator'
import Search from '../../components/Search'
import { KEY_TYPE_OPTIONS } from '../../consts'
import { useLicenseKeys } from '../../hooks/useLicenseKeys'

export default function HwKeys({ needDlic }) {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] = useDebounceQueryParams()

  const filter = useMemo(
    () => ({
      ...debQueryParams,
      needs_dlic: needDlic ? 'true' : undefined,
    }),
    [debQueryParams, needDlic]
  )

  const [{ keys, loading, error, pagination }, { create, run: reload, updateDlic }] = useLicenseKeys(filter)

  const [createModalState, createModalActions] = useModalTrigger()

  const { t } = useTranslation()

  if (error) {
    return <PageError error={error} />
  }

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="mb-4 d-flex justify-content-between">
          <div className="d-flex flex-row align-items-center flex-1">
            <Search
              value={queryParams.search ?? ''}
              onChangeValue={(search) =>
                setDebQueryParams({
                  search,
                  page: 1,
                })
              }
              onClear={() =>
                setQueryParams({
                  search: '',
                  page: 1,
                })
              }
              className="mr-2"
            />
            <select
              className={`form-control mr-2`}
              value={queryParams.type ?? ''}
              onChange={e => setQueryParams({ type: e.target.value, page: 1 })}
            >
              <option value="">
                Tutti i tipi di chiave
              </option>
              {KEY_TYPE_OPTIONS.map((opt) => (
                <option value={opt.value}>{opt.label}</option>
              ))}
            </select>
            <select
              className={`form-control mr-2`}
              value={queryParams.all ?? ''}
              onChange={e => setQueryParams({ all: e.target.value, page: 1 })}
            >
              <option value="false">
                Chiavi attive
              </option>
              <option value="true">
                Tutte le chiavi
              </option>
            </select>
          </div>
          <div className="text-right">
            {loading && <Spinner animation="border" variant="primary" size="sm" className="mr-3" />}
            {!needDlic && (
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => createModalActions.open('dummy')}
                icon={<PlusCircleDotted size={15} />}
                className="mr-2"
              >
                {t('create_key')}
              </Button>
            )}
          </div>
        </div>
        <KeysHwListOrg showCustomer showOEM keys={keys || []} onDlicCompute={updateDlic} orderBy={queryParams.ordering} onOrderingChange={(ord) => {
          setQueryParams({ ordering: ord, page: 1 })
        }} />
        <div style={{ width: '100vw', overflow: 'scroll' }}>
          <Paginator
            numPages={pagination.numPages}
            currentPage={+debQueryParams.page || 1}
            goToPage={(page) => setQueryParams({ page })}
          />
        </div>
        {createModalState.value && (
          <LicenseKeyModal
            isOpen={createModalState.isOpen}
            toggle={createModalActions.close}
            onClosed={createModalActions.onClosed}
            onSave={(result) => {
              return create
                .onSuccess(() => {
                  createModalActions.close()
                  reload(debQueryParams)
                })
                .asPromise(result)
            }}
          />
        )}
      </div>
    </Layout>
  )
}
