import { Alert, Modal, Spinner } from 'react-bootstrap'
import { ArrowLeft, Check, Save2, Save2Fill, X } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import { Field, Formik } from 'formik'
import { ProductLevelTypeaheadField } from '../ProductLevelTypeahead'
import * as Yup from 'yup'
import { useProductLevels } from '../../hooks/useProductLevels'
import { useMemo, useState } from 'react'

export function ModalConvertLicense({ isOpen, toggle, onClosed, license, onSubmit, loading, error }) {
  const bestFitParams = useMemo(() => {
    return {
      legacy_product_code: license.productCodeId,
    }
  }, [license.productCodeId])
  const [{ levels: bestFitLevels, loading: loadingBestFitLevels }] = useProductLevels(bestFitParams)
  const [ignoreBestFit, setIgnoreBestFit] = useState(false)

  const Schema = Yup.object().shape({
    level: Yup.string().required('Required'),
  })

  const { t } = useTranslation()
  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('convert_license')} </Modal.Title>
      </Modal.Header>
      {loadingBestFitLevels && <div className="spinner-border text-primary" role="status" />}
      {((bestFitLevels && bestFitLevels.length === 0) || ignoreBestFit) && (
        <Formik
          initialValues={{
            level: '',
          }}
          onSubmit={(values) => {
            onSubmit(values)
          }}
          validationSchema={Schema}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <>
                <Modal.Body>
                  {error && <div className="alert alert-danger">{error?.response?.error}</div>}
                  <p>Seleziona il livello per la conversione</p>

                  <div className="form-group">
                    <label htmlFor="level">{t('level')}</label>
                    <Field
                      id="level"
                      name="level"
                      component={ProductLevelTypeaheadField}
                      productId={license.id_product}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                  <Button icon={<ArrowLeft />} onClick={toggle} variant="secondary" type="button">
                    {t('close')}
                  </Button>
                  <Button
                    icon={loading ? <Save2Fill /> : <Save2 />}
                    type="submit"
                    disabled={loading}
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    {t('convert_in_the_new_format')}
                  </Button>
                </Modal.Footer>
              </>
            </form>
          )}
        </Formik>
      )}
      {bestFitLevels && bestFitLevels.length > 0 && !ignoreBestFit && (
        <Modal.Body>
          <div>
            Questa licenza ha una corrispondenza per codice prodotto (legacy)
            <br />
            <b>Prodotto: </b>
            {bestFitLevels[0].product_name}
            <br />
            <b>Livello: </b>
            {bestFitLevels[0].name}
            <br />
            Utilizzare questo livello per la conversione?
          </div>
          {error && (
            <Alert variant="danger" className="mt-3">
              {error?.response?.error}
            </Alert>
          )}
          <div className="d-flex flex-row justify-content-end" style={{ gap: 10 }}>
            <Button
              icon={loading ? <Spinner size="sm" /> : <Check />}
              type="button"
              disabled={loading}
              variant="outline-primary"
              onClick={() => {
                onSubmit({ level: bestFitLevels[0].id })
              }}
            >
              {t('yes')}
            </Button>
            <Button
              icon={loading ? <Spinner size="sm" /> : <X />}
              type="button"
              disabled={loading}
              variant="outline-primary"
              onClick={() => {
                setIgnoreBestFit(true)
              }}
            >
              {t('no')}
            </Button>
          </div>
        </Modal.Body>
      )}
    </Modal>
  )
}
