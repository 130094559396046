import classNames from "classnames"
import * as dayjs from 'dayjs'
import parse from 'html-react-parser'
import { Table } from 'react-bootstrap'
import { ExclamationOctagonFill, ShieldPlus } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { OrderingIcon } from '../OrderingIcon'

export default function KeysHwListOrg({ loading, keys, showCustomer, showOEM, onDlicCompute, orderBy, onOrderingChange }) {
  const { t } = useTranslation()

  let notesWidth = 55
  if (showCustomer) {
    notesWidth -= 20
  }
  if (showOEM) {
    notesWidth -= 20
  }

  return (
    <Table loading={loading} responsive hover>
      <thead>
        <tr style={{ whiteSpace: 'nowrap' }}>
          <th className="ddw-w-10">
            {t('number')}
            {typeof onOrderingChange === "function" && <OrderingIcon field="number" ordering={orderBy} onChange={onOrderingChange} />}
          </th>
          {showCustomer && <th className="ddx-w-20">
            {t('customer')}
            {typeof onOrderingChange === "function" && <OrderingIcon field="customer__name" ordering={orderBy} onChange={onOrderingChange} />}
          </th>}
          {showOEM && <th className="ddx-w-20">
            {t('oem')}
            {typeof onOrderingChange === "function" && <OrderingIcon field="oem__name" ordering={orderBy} onChange={onOrderingChange} />}
          </th>}
          <th className="ddx-w-5 text-center">
            <span title={t('needs_dlic_generation')}>{t('dlic')}</span>
          </th>
          <th className="ddx-w-10">
            {t('activation_date')}
            {typeof onOrderingChange === "function" && <OrderingIcon field="activation_date" ordering={orderBy} onChange={onOrderingChange} />}
          </th>
          <th className="ddx-w-10">
            {t('serial_number')}
            {typeof onOrderingChange === "function" && <OrderingIcon field="serial_number" ordering={orderBy} onChange={onOrderingChange} />}
          </th>
          <th className={`ddx-w-${notesWidth}`}>{t('notes')}</th>
          <th className="ddx-w-10">
            {t('type')}
            {typeof onOrderingChange === "function" && <OrderingIcon field="type" ordering={orderBy} onChange={onOrderingChange} />}
          </th>
        </tr>
      </thead>
      <tbody>
        {keys &&
          keys.map((key) => (
            <tr key={key.number}>
              <td style={{ whiteSpace: 'nowrap' }}>
                {key.is_deleted && <ExclamationOctagonFill className="text-danger mr-2" />}
                <Link className={classNames("font-weight-bold", { "text-danger": key.is_deleted })} to={`/hw-keys/${key.number}`}>
                  {key.number}
                </Link>
              </td>
              {showCustomer && (
                <td>
                  <Link to={`/orgs/${key.customer}`}>{key.customer_name} </Link>
                </td>
              )}
              {showOEM && (
                <td>
                  <Link to={`/orgs/${key.oem}`}>{key.oem_name} </Link>
                </td>
              )}
              <td className="text-center">
                {key.needs_dlic ? (
                  <span onClick={() => onDlicCompute(key.number)} className="pointer">
                    <ShieldPlus size={18} />
                  </span>
                ) : null}
              </td>
              <td>{dayjs(key.activation_date).format('DD/MM/YYYY')}</td>
              <td>{key.serial_number}</td>
              <td width={'20%'}>{key.notes ? parse(key.notes) : ''}</td>
              <td>{key.type}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}
