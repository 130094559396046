import classNames from 'classnames'
import { endOfDay, format, formatISO, parseISO, startOfDay } from 'date-fns'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import { useMemo, useState } from 'react'
import { ButtonGroup, Nav, NavDropdown, Tab, Tabs } from 'react-bootstrap'
import {
  Award,
  ChatRightText,
  CloudCheck,
  CloudFill,
  ExclamationDiamond,
  Gear,
  Key,
  Pencil,
  PeopleFill,
  PersonCheck,
  PlusCircleDotted,
  Trash,
} from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { deps, useRunRj } from 'react-rocketjump'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import AssistanceContractsListOrg from '../../components/AssistanceContractsListOrg'
import Button from '../../components/Button'
import KeysHwListOrg from '../../components/KeysHwListOrg'
import Layout from '../../components/Layout'
import LicensePoolModal from '../../components/LicensePoolModal'
import LicensesList from '../../components/LicensesList'
import LicensesListOrgUvi from '../../components/LicensesListOrgUvi'
import ModalConfirmDelete from '../../components/ModalConfirmDelete'
import PageError from '../../components/PageError'
import PageSpinner from '../../components/PageSpinner'
import Paginator from '../../components/Paginator'
import Search from '../../components/Search'
import Table from '../../components/Table'
import { useLicenseKeys } from '../../hooks/useLicenseKeys'
import { AssistanceContractState } from '../../state/assistance-contract'
import { LicensesListState } from '../../state/license'
import { LicensesUvicornState } from '../../state/license-uvicorn'
import { LicencePoolListState } from '../../state/licensePool'
import { OrgDetailUvicornState } from '../../state/orgs-uvicorn'
import { AssistanceContracts } from '../AssistanceContracts'
import { DlicLicenses } from '../DlicLicenses'
import { DangerZone } from '../Org_DangerZone'
// import AddOrgAdminModal from './AddOrgAdminModal'
import { KEY_TYPE_OPTIONS } from '../../consts'
import OrgModal from './OrgModal'

// function OrgAdminsTab({ admins, onAdd, onDelete }) {
//   const { t } = useTranslation()
//   const [addModal, addModalActions] = useModalTrigger()
//   const [rmModal, rmModalActions] = useModalTrigger()

//   return (
//     <div className="mt-2">
//       <div className="mb-2 text-right">
//         <Button
//           size="sm"
//           variant="outline-primary"
//           onClick={() => addModalActions.open()}
//           icon={<PlusCircleDotted size={18} />}
//         >
//           {t('add_organization_admin')}
//         </Button>
//       </div>
//       <Table responsive>
//         <thead>
//           <tr>
//             <th>{t('user_email')}</th>
//             <th>{t('user_name')}</th>
//             <th className="text-right pr-5">
//               <Gear />
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {admins.map((admin) => (
//             <tr key={admin.id}>
//               <td>
//                 <Link className="" to={`/users/${admin.user.id}`}>
//                   {admin.user.email}
//                 </Link>
//               </td>
//               <td>{admin.user.full_name}</td>
//               <td className="text-right">
//                 <Button onClick={() => rmModalActions.open(admin)} size="sm" variant="outline-danger" icon={<Trash />}>
//                   {t('delete_organization_admin')}
//                 </Button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//       <AddOrgAdminModal
//         isOpen={addModal.isOpen}
//         toggle={addModalActions.toggle}
//         onExited={addModalActions.onClosed}
//         onSave={(admin) => onAdd.onSuccess(addModalActions.close).asPromise(admin)}
//       />
//       <ModalConfirmDelete
//         isOpen={rmModal.isOpen}
//         toggle={rmModalActions.toggle}
//         title={
//           rmModal.value
//             ? t('delete_organization_admin_title', {
//                 email: rmModal.value.user.email,
//               })
//             : null
//         }
//         text={
//           rmModal.value
//             ? t('delete_organization_admin_text', {
//                 email: rmModal.value.user.email,
//               })
//             : null
//         }
//         onExited={rmModalActions.onClosed}
//         onDelete={() => {
//           onDelete.onSuccess(rmModalActions.close).run(rmModal.value)
//         }}
//       />
//     </div>
//   )
// }

function OrgLicensePoolsTab({ pools, pagination, currentPage, goToPage, onAdd, onUpdate, onRemove, loading, org }) {
  const { t } = useTranslation()
  const { user } = useAuthUser()
  const [addModal, addModalActions] = useModalTrigger()
  const [editModal, editModalActions] = useModalTrigger()
  const [rmModal, rmModalActions] = useModalTrigger()

  return (
    <div className="mt-2">
      <div className="mb-2 d-flex justify-content-end">
        {user.is_admin && (
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => addModalActions.open('dummy')}
            icon={<PlusCircleDotted size={18} />}
          >
            {t('create_license_pool')}
          </Button>
        )}
      </div>
      <Table responsive loading={loading}>
        <thead>
          <tr>
            <th>{t('id')}</th>
            <th>{t('application')}</th>
            <th>{t('application_version')}</th>
            <th className="text-center">{t('license_pool_size')}</th>
            <th className="text-center">{t('license_pool_used')}</th>
            <th>{t('license_pool_valid_from')}</th>
            <th>{t('license_pool_valid_to')}</th>
            <th>{t('license_pool_license_key')}</th>
            <th className="text-right pr-5">
              <Gear />
            </th>
          </tr>
        </thead>
        <tbody>
          {pools &&
            pools.map((pool) => (
              <tr key={pool.id}>
                <td>
                  <Link className="d-flex align-items-center" to={`/pools/${pool.id}`}>
                    {pool.label === '' ? t('default_pool_label') + ' ' + pool.id : pool.label}
                  </Link>
                </td>
                <td>
                  <Link to={`/apps/${pool.application}`}>{pool.application_data.name}</Link>
                </td>
                <td>{pool.application_data.version}</td>
                <td className="text-center">{pool.size}</td>
                <td className="text-center">{pool.used}</td>
                <td>{format(parseISO(pool.valid_from), 'dd-MM-yyyy')}</td>
                <td>{format(parseISO(pool.valid_to), 'dd-MM-yyyy')}</td>
                <td>{pool.hardware_key_number}</td>
                <td className="text-right">
                  {user.is_admin && (
                    <>
                      <Button
                        onClick={() => editModalActions.open(pool)}
                        icon={<Pencil />}
                        variant={'outline-primary'}
                        className="mr-2"
                        size="sm"
                      >
                        {t('edit_license_pool')}
                      </Button>
                      <Button
                        disabled={pool.registered !== 0}
                        onClick={() => rmModalActions.open(pool)}
                        icon={<Trash />}
                        variant={'outline-danger'}
                        size="sm"
                      >
                        {t('delete_license_pool')}
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Paginator numPages={pagination.numPages} currentPage={currentPage} goToPage={goToPage} />
      {addModal.value && (
        <LicensePoolModal
          onSave={(licensePool) =>
            // NOTE: Workaround to hack licencse pool object
            onAdd(licensePool).onSuccess(addModalActions.close).asPromise()
          }
          isOpen={addModal.isOpen}
          toggle={addModalActions.toggle}
          onExited={addModalActions.onClosed}
          customerId={org.id}
        />
      )}
      {editModal.value && (
        <LicensePoolModal
          onSave={(licensePool) => onUpdate(licensePool).onSuccess(editModalActions.close).asPromise()}
          licensePool={editModal.value}
          isOpen={editModal.isOpen}
          toggle={editModalActions.toggle}
          onExited={editModalActions.onClosed}
          customerId={org.id}
        />
      )}
      <ModalConfirmDelete
        isOpen={rmModal.isOpen}
        toggle={rmModalActions.toggle}
        title={t('delete_license_pool')}
        text={
          rmModal.value
            ? t('delete_license_pool_text', {
              size: rmModal.value.size,
              app_name: rmModal.value.application_data.name,
            })
            : null
        }
        onExited={rmModalActions.onClosed}
        onDelete={() => {
          onRemove.onSuccess(rmModalActions.close).run(rmModal.value)
        }}
      />
    </div>
  )
}

function OrgDetailTab1({ activeTab, eventKey, icon: Icon, title }) {
  const { t } = useTranslation()
  const isActive = activeTab === eventKey
  return (
    <Nav.Item role="presentation">
      <Nav.Link eventKey={eventKey} active={isActive} style={{ height: 38 }}>
        <h5
          className={classNames('d-flex align-items-center m-0', { 'text-dark': !isActive, 'text-primary': isActive })}
        >
          <Icon className="mr-1" /> {t(title)}
        </h5>
      </Nav.Link>
    </Nav.Item>
  )
}

function OrgDetailTab2({ activeTab, eventKey, icon: Icon, title }) {
  const { t } = useTranslation()
  return (
    <NavDropdown.Item role="presentation" eventKey={eventKey}>
      <h5 className={classNames('d-flex align-items-center m-0')}>
        <Icon className="mr-1" /> {t(title)}
      </h5>
    </NavDropdown.Item>
  )
}

export default function OrgDetail() {
  const { t } = useTranslation()
  const { user } = useAuthUser()
  const history = useHistory()
  const { id, tab } = useParams()
  const handleChangeTab = (tab) => history.push(`/orgs/${id}/${tab}`)

  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] = useDebounceQueryParams()

  const [{ org, error }, { deleteOrg, updateOrg }] = useRunRj(OrgDetailUvicornState, [id])

  const filterCustomer = useMemo(() => {
    const { keysPage, keysSearch, ...otherParams } = debQueryParams
    return { ...otherParams, customerId: id }
  }, [id, debQueryParams])

  const filterKeys = useMemo(
    () => ({
      customer: id,
      page: debQueryParams.keysPage,
      search: debQueryParams.keysSearch,
      ordering: debQueryParams.keysOrdering,
      type: debQueryParams.keysFilterType,
    }),
    [id, debQueryParams.keysPage, debQueryParams.keysSearch, debQueryParams.keysOrdering, debQueryParams.keysFilterType]
  )

  const [{ contracts, loading: loadingContracts }] = useRunRj(AssistanceContractState, [filterCustomer], false)
  const [{ keys, loading: loadingKeys, pagination: paginationKeys }] = useLicenseKeys(filterKeys)

  const [{ licensesUvi, loading: loadingLicensesUvi, pagination: paginationLicenses }] = useRunRj(
    LicensesUvicornState,
    [filterCustomer],
    false
  )

  const [editModal, editModalActions] = useModalTrigger()
  const [rmModal, rmModalActions] = useModalTrigger()

  const [poolsPage, setPoolsPage] = useState(1)
  const poolsListFilters = useMemo(
    () =>
      org
        ? {
          organization: org.id,
          page: poolsPage,
        }
        : deps.maybe(),
    [poolsPage, org]
  )
  const [
    { pools, pagination: poolsPagination, loading: loadingPools },
    { run: refetchPools, addLicensePool, updateLicensePool, removeLicensePool },
  ] = useRunRj(LicencePoolListState, [poolsListFilters], false)

  const [licensesRawFilters, setLicensesRawFilters] = useState({
    page: 1,
    application: null,
    valid: '',
  })
  const licensesFilters = useMemoCompare(
    org
      ? {
        ...licensesRawFilters,
        application: licensesRawFilters.application?.id ?? '',
        organization: org.id,
      }
      : deps.maybe()
  )

  const [{ licenses, pagination: licensesPagination, loading: loadingLicenses }, { run: fetchLicenses }] = useRunRj(
    LicensesListState,
    [licensesFilters],
    false
  )

  const [licensesType, setLicensesType] = useState('licenses')
  const [contractsType, setContractsType] = useState('contracts')

  if (error) {
    return <PageError error={error} />
  }

  if (org === null) {
    return <PageSpinner />
  }

  return (
    <Layout>
      <div className="container-fluid mt-3">
        <div className="border-bottom pb-2 d-flex justify-content-between align-items-end">
          <h2 className="m-0 d-flex aling-items-center justify-content-center">
            <PeopleFill size={31} className="mr-2" /> {org.businessName}
          </h2>
          {/* <div>
            {user.is_admin && (
              <>
                <Button
                  onClick={() => editModalActions.open()}
                  className="mr-2"
                  size="sm"
                  disabled
                  variant="outline-primary"
                  icon={<Pencil />}
                >
                  {t('edit_organization')}
                </Button>
                <Button
                  onClick={() => rmModalActions.open()}
                  size="sm"
                  disabled
                  variant="outline-danger"
                  icon={<Trash />}
                >
                  {t('delete_organization')}
                </Button>
              </>
            )}
          </div> */}
        </div>
        <div>
          <Nav role="tablist" variant="tabs" onSelect={handleChangeTab}>
            <OrgDetailTab1 activeTab={tab} eventKey="dlic-licenses" title="dlic_licenses" icon={Award} />
            <OrgDetailTab1
              activeTab={tab}
              eventKey="assistance-contract"
              title="assistance_contracts"
              icon={ChatRightText}
            />
            <OrgDetailTab1 activeTab={tab} eventKey="hw-keys" title="hw_keys" icon={Key} />
            <OrgDetailTab1 activeTab={tab} eventKey="danger-zone" title="danger_zone" icon={ExclamationDiamond} />
            <NavDropdown
              title={
                <h5
                  className="d-inline-flex align-items-center text-dark m-0"
                  style={{ transform: 'translateY(-2px)' }}
                >
                  {t('cloud')}
                </h5>
              }
            >
              <OrgDetailTab2 activeTab={tab} eventKey="pools" title="license_pools" icon={CloudFill} />
              <OrgDetailTab2 activeTab={tab} eventKey="licenses-cloud" title="licenses_cloud" icon={CloudCheck} />
              {user.is_admin && (
                <OrgDetailTab2 activeTab={tab} eventKey="admins" title="organization_admins" icon={PersonCheck} />
              )}
            </NavDropdown>
          </Nav>

          <Tabs onSelect={handleChangeTab} className="tabs-orgs" activeKey={tab} id="org-tabs">
            <Tab eventKey="pools">
              <OrgLicensePoolsTab
                loading={loadingPools}
                pools={pools}
                pagination={poolsPagination}
                currentPage={poolsPage}
                goToPage={(page) => setPoolsPage(page)}
                org={org}
                onRemove={removeLicensePool}
                onUpdate={(licensePool) =>
                  updateLicensePool
                    .onSuccess(() => {
                      fetchLicenses(licensesFilters)
                    })
                    .curry({
                      ...licensePool,
                      valid_from: formatISO(startOfDay(new Date(licensePool.valid_from))),
                      valid_to: formatISO(endOfDay(new Date(licensePool.valid_to))),
                    })
                }
                onAdd={(licensePool) =>
                  addLicensePool
                    .onSuccess(() => refetchPools(poolsListFilters))
                    .curry({
                      ...licensePool,
                      application: licensePool.application.id,
                      valid_from: formatISO(startOfDay(new Date(licensePool.valid_from))),
                      valid_to: formatISO(endOfDay(new Date(licensePool.valid_to))),
                      organization: org.id,
                    })
                }
              />
            </Tab>
            {user.is_admin && (
              <Tab eventKey="admins">
                {/* <OrgAdminsTab
                admins={org.admins}
                onAdd={addAdmin.curry(org.id)}
                onDelete={removeAdmin.curry(org.id)}
              /> */}
                <p>Admins are temporarily unavailable due to back-compatibility issues</p>
              </Tab>
            )}
            <Tab eventKey="dlic-licenses">
              <ButtonGroup className="mt-3 ml-3">
                <Button
                  size="md"
                  icon={<Award />}
                  className="pb-2"
                  variant={licensesType === 'licenses' ? 'primary' : 'outline-primary'}
                  onClick={() => {
                    setLicensesType('licenses')
                  }}
                >
                  Licenze
                </Button>
                <Button
                  size="md"
                  icon={<Award />}
                  variant={licensesType === 'licenses-cloud' ? 'primary' : 'outline-primary'}
                  onClick={() => {
                    setLicensesType('licenses-cloud')
                  }}
                >
                  Licenze Legacy
                </Button>
              </ButtonGroup>
              {licensesType === 'licenses' ? (
                <DlicLicenses org={org} />
              ) : (
                <>
                  <div className="container-fluid mt-4">
                    <div className="mb-2 mt-2 row">
                      <div className="col-md-3">
                        <Search
                          value={queryParams.search ?? ''}
                          onChangeValue={(search) =>
                            setDebQueryParams({
                              search,
                              page: 1,
                              expired: queryParams.expired ?? '',
                            })
                          }
                          onClear={() =>
                            setQueryParams({
                              search: '',
                              page: 1,
                              expired: '',
                            })
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <select
                          value={queryParams.expired ?? ''}
                          onChange={(e) => {
                            if (e.target.value !== '') {
                              setDebQueryParams({
                                search: queryParams.search ?? '',
                                page: 1,
                                expired: e.target.value,
                              })
                            } else {
                              setDebQueryParams({
                                search: queryParams.search ?? '',
                                page: 1,
                                expired: '',
                              })
                            }
                          }}
                          name="expired"
                          className="form-control"
                        >
                          <option value={''}>{t('filter_licenses')}</option>
                          <option value={0}>{t('actives')}</option>
                          <option value={1}>{t('licenses_expired')}</option>
                        </select>
                      </div>
                    </div>
                    <LicensesListOrgUvi showCustomer licenses={licensesUvi || []} loading={loadingLicensesUvi} />
                  </div>
                  <div style={{ width: '100vw', overflow: 'scroll' }}>
                    <Paginator
                      numPages={paginationLicenses.numPages}
                      currentPage={+debQueryParams.page || 1}
                      goToPage={(page) => setQueryParams({ page })}
                    />
                  </div>
                </>
              )}
            </Tab>
            <Tab eventKey="licenses-cloud">
              <LicensesList
                showUser
                loading={loadingLicenses}
                licenses={licenses}
                pagination={licensesPagination}
                currentPage={licensesRawFilters.page}
                goToPage={(page) => setLicensesRawFilters((filters) => ({ ...filters, page }))}
                filters={licensesRawFilters}
                onFiltersChange={(filters) =>
                  setLicensesRawFilters((prev) => ({
                    ...prev,
                    ...filters,
                    page: 1,
                  }))
                }
              />
            </Tab>
            <Tab eventKey="assistance-contract">
              <ButtonGroup className="mt-3 ml-3">
                <Button
                  size="md"
                  icon={<Award />}
                  className="pb-2"
                  variant={contractsType === 'contracts' ? 'primary' : 'outline-primary'}
                  onClick={() => {
                    setContractsType('contracts')
                  }}
                >
                  Contratti assistenza
                </Button>
                <Button
                  size="md"
                  icon={<Award />}
                  variant={contractsType === 'contracts-legacy' ? 'primary' : 'outline-primary'}
                  onClick={() => {
                    setContractsType('contracts-legacy')
                  }}
                >
                  Contratti assistenza Legacy
                </Button>
              </ButtonGroup>
              {contractsType === 'contracts' ? (
                <AssistanceContracts org={org} />
              ) : (
                <AssistanceContractsListOrg contracts={contracts || []} loading={loadingContracts} />
              )}
            </Tab>
            <Tab eventKey="assistance-contract-legacy">
              <AssistanceContractsListOrg contracts={contracts || []} loading={loadingContracts} />
            </Tab>
            <Tab eventKey="danger-zone">
              <DangerZone org={org} />
            </Tab>
            <Tab eventKey="hw-keys">
              <div className="mb-2 mt-2 row">
                <div className="d-flex flex-row justify-content-start align-items-start ml-3 mr-3">
                  <Search
                    value={queryParams.keysSearch ?? ''}
                    onChangeValue={(search) =>
                      setDebQueryParams({
                        keysSearch: search,
                        keysPage: 1,
                      })
                    }
                    onClear={() =>
                      setQueryParams({
                        keysSearch: '',
                        keysPage: 1,
                      })
                    }
                    className='mr-2'
                  />
                  <select
                    className={`form-control`}
                    value={queryParams.keysFilterType}
                    onChange={e => setQueryParams({ keysFilterType: e.target.value, keysPage: 1 })}
                  >
                    <option value="">
                      Tutti i tipi di chiave
                    </option>
                    {KEY_TYPE_OPTIONS.map((opt) => (
                      <option value={opt.value}>{opt.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <KeysHwListOrg showOEM keys={keys || []} loading={loadingKeys} orderBy={queryParams.keysOrdering} onOrderingChange={ord => setQueryParams({ keysOrdering: ord, keysPage: 1 })} />
              <div style={{ width: '100vw', overflow: 'scroll' }}>
                <Paginator
                  numPages={paginationKeys.numPages}
                  currentPage={paginationKeys?.current?.page || 1}
                  goToPage={(page) => setQueryParams({ keysPage: page })}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>

      <ModalConfirmDelete
        isOpen={rmModal.isOpen}
        toggle={rmModalActions.toggle}
        title={`Delete Organization ${org.name}`}
        text={`Are you sure to remove Organization ${org.name}?`}
        onExited={rmModalActions.onClosed}
        onDelete={() => {
          deleteOrg.onSuccess(() => history.push('/orgs')).run(org)
        }}
      />
      <OrgModal
        onSave={(org) => updateOrg.onSuccess(editModalActions.close).asPromise(org)}
        org={org}
        isOpen={editModal.isOpen}
        toggle={editModalActions.toggle}
        onExited={editModalActions.onClosed}
      />
    </Layout>
  )
}
