import itFlag from './assets/it.svg'
import enFLag from './assets/en.svg'
import deFlag from './assets/de.svg'
import frFlag from './assets/fr.svg'
import esFlag from './assets/es.svg'
import plFlag from './assets/pl.svg'
import zhFlag from './assets/zh.svg'

export const PAGE_SIZE = 100

export const LANGUAGES = {
  it: {
    label: 'italiano',
  },
  en: {
    label: 'english',
  },
  de: {
    label: 'Deutsch',
  },
  fr: {
    label: 'français',
  },
  es: {
    label: 'español',
  },
  pl: {
    label: 'polski',
  },
  // NOTE: zh-hans has troubles with supported langs....
  zh: {
    label: '简体中文',
  }
}

export const ICONS_FLAG = {
  it: itFlag,
  en: enFLag,
  de: deFlag,
  fr: frFlag,
  es: esFlag,
  pl: plFlag,
  zh: zhFlag,
}

export const LANGUAGE_CODES = Object.keys(LANGUAGES)

export const API_URL_UVICORN = "/uvi"

export const KEY_TYPE_OPTIONS = [
  { value: 'sentinel-hl', label: 'sentinel-hl' },
  { value: 'sentinel-hl-net', label: 'sentinel-hl-net' },
  { value: 'sentinel-sl', label: 'sentinel-sl' },
  { value: 'sentinel-sl-net', label: 'sentinel-sl-net' },
  { value: 'sentinel-sl-vm', label: 'sentinel-sl-vm' },
  { value: 'sentinel-sl-trial', label: 'sentinel-sl-trial' },
  { value: 'smartkey', label: 'smartkey' },
  { value: 'smartkey-net', label: 'smartkey-net' },
  { value: 'sentinel-cl', label: 'sentinel-cl' },
  { value: 'AutoNester', label: 'AutoNester' },
  { value: 'Software', label: 'Software' },
  { value: 'Parallela', label: 'Parallela' },
  { value: 'NestLib', label: 'NestLib' },
  { value: 'Hasp', label: '[DEPRECATED] Hasp' },
  { value: 'Hasp Rete', label: '[DEPRECATED] Hasp Rete' },
  { value: 'Hasp Software', label: '[DEPRECATED] Hasp Software' },
  { value: 'Trial Software', label: '[DEPRECATED] Trial Software' },
  { value: 'Eutron', label: '[DEPRECATED] Eutron' },
  { value: 'Eutron Rete', label: '[DEPRECATED] Eutron Rete' },
]