import dayjs from 'dayjs'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import React, { useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { PlusCircleDotted, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import Paginator from '../../components/Paginator'
import Search from '../../components/Search'
import { useLicenses } from '../../hooks/useLicenses'
import { CreateLicenseModal } from './CreateLicenseModal'
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete'

export function DlicLicenses({ org }) {
  const history = useHistory()
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] = useDebounceQueryParams()

  const { t } = useTranslation()

  const filters = useMemo(
    () => ({
      ...debQueryParams,
      customer: org.id,
    }),
    [debQueryParams, org.id]
  )

  const [{ licenses, loading, error, pagination }, { create, remove, run: reload }] = useLicenses(filters)

  const [createModalState, createModalActions] = useModalTrigger()
  const [delModalState, delModalActions] = useModalTrigger()

  if (error) {
    return <div>Si è verificato un errore</div>
  }

  return (
    <div className="container-fluid mt-4">
      <div className="mb-4 row justify-content-between">
        <div className="col-md-3">
          <Search
            value={queryParams.search ?? ''}
            onChangeValue={(search) =>
              setDebQueryParams({
                search,
                page: 1,
                expired: queryParams.expired ?? '',
              })
            }
            onClear={() =>
              setQueryParams({
                search: '',
                page: 1,
                expired: '',
              })
            }
          />
        </div>
        <div className="col-md-3 text-right">
          <Button
            size="sm"
            variant="outline-primary"
            icon={<PlusCircleDotted size={18} />}
            onClick={() => {
              createModalActions.open('dummy')
            }}
          >
            {t('create_license')}
          </Button>
        </div>
      </div>
      <Table loading={loading} responsive hover>
        <thead>
          <tr>
            <th>{t('id')}</th>
            <th>{t('product')}</th>
            <th>{t('dlic_license_expire_date')}</th>
            <th>{t('dlic_license_quantity')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {licenses &&
            licenses.map((license) => (
              <tr key={license.id}>
                <td>
                  <Link className="font-weight-bold" to={`/licenseconfig/${license.id}`}>
                    {license.id}
                  </Link>
                </td>
                <td>{license.commercial_name}</td>
                <td>
                  {license.license_type === 'perpetual' && <span>{t('dlic_license_perpetual')}</span>}
                  {license.license_type === 'expire' && <span>{dayjs(license.expire_date).format('DD/MM/YYYY')}</span>}
                </td>
                <td>{license.quantity}</td>
                <td className='text-right'>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => delModalActions.open(license)}
                    icon={<Trash size={15} />}
                  >
                    {t('delete')}
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div style={{ width: '100vw', overflow: 'scroll' }}>
        <Paginator
          numPages={pagination.numPages}
          currentPage={+debQueryParams.page || 1}
          goToPage={(page) => setQueryParams({ page })}
        />
      </div>
      {createModalState.value && (
        <CreateLicenseModal
          isOpen={createModalState.isOpen}
          onClosed={createModalActions.onClosed}
          onCancel={createModalActions.close}
          onSubmit={(values) => {
            return create
              .onSuccess((result) => {
                createModalActions.close()
                history.push(`/licenseconfig/${result.id}`)
              })
              .asPromise({
                service_level: values.service_level,
                min_version: values.min_version,
                attributes: {},
                license_type: 'perpetual',
                oem: null,
                quantity: 1,
                notes: '',
                customer: org.id,
              })
          }}
        />
      )}
      {delModalState.value && (
        <ModalConfirmDelete
          onDelete={() => {
            remove
              .onSuccess(() => {
                delModalActions.close()
                reload(filters)
              })
              .run(delModalState.value.id)
          }}
          isOpen={delModalState.isOpen}
          toggle={delModalActions.toggle}
          onExited={delModalActions.onClosed}
          title={t('delete_dlic_license_title', {
            id: delModalState.value.id,
          })}
          text={t('delete_dlic_license_text', {
            id: delModalState.value.id,
          })}
        />
      )}
    </div>
  )
}
