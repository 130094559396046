import { getIn } from 'formik'
import keyBy from 'lodash/keyBy'
import { useEffect, useRef } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useProducts } from '../hooks/useProducts'

export function ProductTypeahead({
  value,
  name = '',
  id = 'product-typeahead',
  className,
  onChange,
  ...props
}) {
  const [{ products, loading }, { run }] = useProducts.controlled()
  const valueObj = value
    ? products?.find((item) => item.id === value)
    : undefined
  const selected = valueObj ? [valueObj] : []
  const allOptionsIndex = useRef([])

  useEffect(
    () =>
      run
        .onSuccess((result) => {
          allOptionsIndex.current = keyBy(result, 'id')
        })
        .run(),
    [run]
  )

  return (
    <Typeahead
      inputProps={{
        name,
        className,
      }}
      // onBlur={field.onBlur}
      onChange={(values) => onChange(values.length ? values[0] : null)}
      // filterBy={() => true}
      selected={selected}
      onInputChange={(search) => {
        run.withMeta({ debounced: true }).run({ search })
      }}
      isLoading={loading}
      options={products ?? []}
      labelKey={(opt) => `${opt.name}`}
      minLength={0}
      onSearch={(search) => run({ search })}
      id={id}
      {...props}
    />
  )
}

export function ProductTypeaheadField({
  field,
  label,
  form,
  required = false,
  onChange,
  ...props
}) {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  return (
    <div className="form-group">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">{' *'}</span>}
        </label>
      )}
      <ProductTypeahead
        name={field.name}
        className={error && touch ? 'is-invalid' : undefined}
        onBlur={field.onBlur}
        onChange={(value) => {
          form.setFieldValue(field.name, value?.id ?? '')
          onChange?.(value)
        }}
        value={field.value}
        id={`option-typehead-for-${field.name}`}
        {...props}
      />
      {error && touch && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </div>
  )
}
