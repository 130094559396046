import { Field, Formik } from 'formik'
import { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { ArrowLeft, Cart, CheckCircle, PlusCircleDotted } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Button from '../Button'
import { CheckboxField, InputField, TextAreaField, transformErrorForForm } from '../Form'

const INITIAL_VALUES = {
  code: '',
  name: '',
  is_external: false,
  description: '',
  min_version_new_licenses: null,
}

export function ProductModal({ onSave, isOpen, toggle, onClosed, product = INITIAL_VALUES, action = 'edit' }) {
  const { t } = useTranslation()
  const schema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string().required(
          t('form_required', {
            field: t('code'),
          })
        ),
        name: Yup.string().required(
          t('form_required', {
            field: t('name'),
          })
        ),
        is_external: Yup.bool().notRequired(),
        description: Yup.string().notRequired(),
      }),
    [t]
  )

  return (
    <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
      <Formik
        validationSchema={schema}
        initialValues={{ ...product, min_version_new_licenses: product.min_version_new_licenses || '' }}
        onSubmit={(values, actions) =>
          onSave({ ...values, min_version_new_licenses: values.min_version_new_licenses || null }).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <Cart className="mr-2" />
                  {t('create_product')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field required label={t('code')} name="code" component={InputField} />
                <Field required label={t('name')} name="name" component={InputField} />
                <Field label={t('description')} name="description" component={TextAreaField} rows={4} />
                <Field
                  label={t('min_version_new_licenses')}
                  hint={t('min_version_new_licenses_help')}
                  type="number"
                  step={1}
                  name="min_version_new_licenses"
                  component={InputField}
                />
                <Field label={t('is_external')} name="is_external" component={CheckboxField} />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button icon={<ArrowLeft />} onClick={toggle} variant="secondary" disabled={isSubmitting} type="button">
                  {t('undo')}
                </Button>
                {action === 'create' && (
                  <Button icon={<PlusCircleDotted size={18} />} variant="success" disabled={isSubmitting} type="submit">
                    {t('create')}
                  </Button>
                )}
                {action === 'edit' && (
                  <Button icon={<CheckCircle size={18} />} variant="success" disabled={isSubmitting} type="submit">
                    {t('save')}
                  </Button>
                )}
              </Modal.Footer>
            </>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
