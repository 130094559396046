import { rj, useRunRj } from 'react-rocketjump'
import rjList, { nextPreviousPaginationAdapter } from 'react-rocketjump/plugins/list'
import { api2 } from '../api'
import { PAGE_SIZE } from '../consts'

const LicenseKeysRj = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    name: 'LicenseKeysRj',
    effectCaller: 'configured',
    effect: (token) => (params) => api2.auth(token).get(`/license/key/`, params),
    computed: {
      keys: 'getList',
      loading: 'isLoading',
      pagination: 'getPagination',
    },
    mutations: {
      create: rj.mutation.single({
        effect: (token) => (data) => {
          return api2.auth(token).post(`/license/key/`, data)
        },
        updater: (state, result) => state,
      }),
      updateDlic: rj.mutation.multi((id) => id, {
        effect: (token) => (number) => {
          return api2
            .auth(token)
            .mapResponse(() => number)
            .get(`/license/dlic/compute/`, { hardware_key: number })
        },
        updater: (state, result) => ({
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map((item) => (item.number === result ? { ...item, needs_dlic: false } : item)),
          },
        }),
      }),
    },
  }
)

export function useLicenseKeys(filters) {
  return useRunRj(LicenseKeysRj, [filters], false)
}

const LicenseKeyRj = rj({
  name: 'LicenseKeyRj',
  effectCaller: 'configured',
  effect: (token) => (id) => api2.auth(token).get(`/license/key/${id}`, { all: true }),
  computed: {
    key: 'getData',
    error: 'getError',
  },
  mutations: {
    uploadC2V: rj.mutation.single({
      effect: (token) => (id, file) => {
        const fd = new FormData()
        fd.append('last_c2v', file)
        return api2.auth(token).patch(`/license/key/${id}/`, fd)
      },
      updater: 'updateData',
    }),
    getC2V: rj.mutation.single({
      effect: (token) => (id) => {
        return api2.auth(token).request({ responseType: 'blob' }).get(`/license/key/${id}/c2v/`)
      },
      updater: (state, result) => state,
    }),
    update: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api2.auth(token).patch(`/license/key/${id}/?all=true`, data)
      },
      updater: 'updateData',
    }),
    destroy: rj.mutation.single({
      effect: (token) => (id) => {
        return api2.auth(token).delete(`/license/key/${id}/`)
      },
      updater: state => ({ ...state, data: { ...state.data, is_deleted: true } }),
    }),
  },
})

export function useLicenseKey(id) {
  return useRunRj(LicenseKeyRj, [id])
}
