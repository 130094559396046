import classnames from "classnames"

export function OrderingIcon({ field, ordering, className="ml-2", onChange }) {
  return (
    <svg style={{ width: "0.8em", height: "0.8em" }} className={classnames(className, "pointer")} viewBox="0 0 20 20" onClick={() => {
      if (field === ordering) {
        onChange("-" + field)
      } else if ("-" + field === ordering) {
        onChange(null)
      } else {
        onChange(field)
      }
    }}>
      <path d="M6 19 L6 1 L1 6 M 6 1 L 11 6" stroke={field === ordering ? "var(--primary)" : "lightgray"} strokeWidth="2" fill="none" />
      <path d="M14 1 L14 19 L19 14 M 14 19 L 9 14" stroke={"-" + field === ordering ? "var(--primary)" : "lightgray"} strokeWidth="2" fill="none" />
    </svg>
  )
}